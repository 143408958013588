export type Locale = 'de' | 'fr' | 'it' | 'rm';

export const LANGUAGE_BY_CODE = {
  aa: {
    '639-1': 'aa',
    '639-2': 'aar',
    key: 'afar',
    en: 'Afar',
    fr: 'Afar',
    de: 'Danakil-Sprache',
    native: 'Afar',
    it: 'Afar',
  },
  ab: {
    '639-1': 'ab',
    '639-2': 'abk',
    key: 'abkhazian',
    en: 'Abkhazian',
    fr: 'Abkhaze',
    de: 'Abchasisch',
    native: 'Аҧсуа',
    it: 'Abcaso',
  },
  af: {
    '639-1': 'af',
    '639-2': 'afr',
    key: 'afrikaans',
    en: 'Afrikaans',
    fr: 'Afrikaans',
    de: 'Afrikaans',
    native: 'Afrikaans',
    it: 'Afrikaans',
  },
  ak: {
    '639-1': 'ak',
    '639-2': 'aka',
    key: 'akan',
    en: 'Akan',
    fr: 'Akan',
    de: 'Akan-Sprache',
    native: 'Akana',
    it: 'Akan',
  },
  am: {
    '639-1': 'am',
    '639-2': 'amh',
    key: 'amharic',
    en: 'Amharic',
    fr: 'Amharique',
    de: 'Amharisch',
    native: 'አማርኛ',
    it: 'Amarico',
  },
  an: {
    '639-1': 'an',
    '639-2': 'arg',
    key: 'aragonese',
    en: 'Aragonese',
    fr: 'Aragonais',
    de: 'Aragonesisch',
    native: 'Aragonés',
    it: 'Aragonese',
  },
  ar: {
    '639-1': 'ar',
    '639-2': 'ara',
    key: 'arabic',
    en: 'Arabic',
    fr: 'Arabe',
    de: 'Arabisch',
    native: 'العربية',
    it: 'Arabo',
  },
  as: {
    '639-1': 'as',
    '639-2': 'asm',
    key: 'assamese',
    en: 'Assamese',
    fr: 'Assamais',
    de: 'Assamesisch',
    native: 'অসমীয়া',
    it: 'Assamese',
  },
  av: {
    '639-1': 'av',
    '639-2': 'ava',
    key: 'avaric',
    en: 'Avaric',
    fr: 'Avar',
    de: 'Awarisch',
    native: 'Авар',
    it: 'Avarico',
  },
  ay: {
    '639-1': 'ay',
    '639-2': 'aym',
    key: 'aymara',
    en: 'Aymara',
    fr: 'Aymara',
    de: 'Aymará-Sprache',
    native: 'Aymar',
    it: 'Aymara',
  },
  az: {
    '639-1': 'az',
    '639-2': 'aze',
    key: 'azerbaijani',
    en: 'Azerbaijani',
    fr: 'Azéri',
    de: 'Aserbeidschanisch',
    native: 'Azərbaycanca',
    it: 'Azero',
  },
  ba: {
    '639-1': 'ba',
    '639-2': 'bak',
    key: 'bashkir',
    en: 'Bashkir',
    fr: 'Bachkir',
    de: 'Baschkirisch',
    native: 'Башҡорт',
    it: 'Bashkir',
  },
  be: {
    '639-1': 'be',
    '639-2': 'bel',
    key: 'belarusian',
    en: 'Belarusian',
    fr: 'Biélorusse',
    de: 'Weißrussisch',
    native: 'Беларуская',
    it: 'Bielorusso',
  },
  bg: {
    '639-1': 'bg',
    '639-2': 'bul',
    key: 'bulgarian',
    en: 'Bulgarian',
    fr: 'Bulgare',
    de: 'Bulgarisch',
    native: 'Български',
    it: 'Bulgaro',
  },
  bi: {
    '639-1': 'bi',
    '639-2': 'bis',
    key: 'bislama',
    en: 'Bislama',
    fr: 'Bichlamar',
    de: 'Beach-la-mar',
    native: 'Bislama',
    it: 'Bislama',
  },
  bm: {
    '639-1': 'bm',
    '639-2': 'bam',
    key: 'bambara',
    en: 'Bambara',
    fr: 'Bambara',
    de: 'Bambara-Sprache',
    native: 'Bamanankan',
    it: 'Bambara',
  },
  bn: {
    '639-1': 'bn',
    '639-2': 'ben',
    key: 'bengali',
    en: 'Bengali',
    fr: 'Bengali',
    de: 'Bengali',
    native: 'বাংলা',
    it: 'Bengalese',
  },
  bo: {
    '639-1': 'bo',
    '639-2': 'bod',
    key: 'tibetan',
    en: 'Tibetan',
    fr: 'Tibétain',
    de: 'Tibetisch',
    native: 'བོད་ཡིག',
    it: 'Tibetano',
  },
  br: {
    '639-1': 'br',
    '639-2': 'bre',
    key: 'breton',
    en: 'Breton',
    fr: 'Breton',
    de: 'Bretonisch',
    native: 'Brezhoneg',
    it: 'Bretone',
  },
  bs: {
    '639-1': 'bs',
    '639-2': 'bos',
    key: 'bosnian',
    en: 'Bosnian',
    fr: 'Bosniaque',
    de: 'Bosnisch',
    native: 'Bosanski',
    it: 'Bosniaco',
  },
  ca: {
    '639-1': 'ca',
    '639-2': 'cat',
    key: 'catalan',
    en: 'Catalan',
    fr: 'Catalan',
    de: 'Katalanisch',
    native: 'Català',
    it: 'Catalano',
  },
  ce: {
    '639-1': 'ce',
    '639-2': 'che',
    key: 'chechen',
    en: 'Chechen',
    fr: 'Tchétchène',
    de: 'Tschetschenisch',
    native: 'Нохчийн',
    it: 'Ceceno',
  },
  ch: {
    '639-1': 'ch',
    '639-2': 'cha',
    key: 'chamorro',
    en: 'Chamorro',
    fr: 'Chamorro',
    de: 'Chamorro-Sprache',
    native: 'Chamoru',
    it: 'Chamorro',
  },
  co: {
    '639-1': 'co',
    '639-2': 'cos',
    key: 'corsican',
    en: 'Corsican',
    fr: 'Corse',
    de: 'Korsisch',
    native: 'Corsu',
    it: 'Corso',
  },
  cr: {
    '639-1': 'cr',
    '639-2': 'cre',
    key: 'cree',
    en: 'Cree',
    fr: 'Cree',
    de: 'Cree-Sprache',
    native: 'Nehiyaw',
    it: 'Cree',
  },
  cs: {
    '639-1': 'cs',
    '639-2': 'ces',
    key: 'czech',
    en: 'Czech',
    fr: 'Tchèque',
    de: 'Tschechisch',
    native: 'Česky',
    it: 'Ceco',
  },
  cu: {
    '639-1': 'cu',
    '639-2': 'chu',
    key: 'church',
    en: 'Church',
    fr: 'Slavon',
    de: 'Kirchenslawisch',
    native: 'Словѣньскъ',
    it: 'Chiesa',
  },
  cv: {
    '639-1': 'cv',
    '639-2': 'chv',
    key: 'chuvash',
    en: 'Chuvash',
    fr: 'Tchouvache',
    de: 'Tschuwaschisch',
    native: 'Чăваш',
    it: 'Chuvash',
  },
  cy: {
    '639-1': 'cy',
    '639-2': 'cym',
    key: 'welsh',
    en: 'Welsh',
    fr: 'Gallois',
    de: 'Kymrisch',
    native: 'Cymraeg',
    it: 'Gallese',
  },
  da: {
    '639-1': 'da',
    '639-2': 'dan',
    key: 'danish',
    en: 'Danish',
    fr: 'Danois',
    de: 'Dänisch',
    native: 'Dansk',
    it: 'Danese',
  },
  de: {
    '639-1': 'de',
    '639-2': 'ger',
    key: 'german',
    en: 'German',
    fr: 'Allemand',
    de: 'Deutsch',
    native: 'Deutsch',
    it: 'Tedesco',
  },
  dv: {
    '639-1': 'dv',
    '639-2': 'div',
    key: 'divehi',
    en: 'Divehi',
    fr: 'Maldivien',
    de: 'Maledivisch',
    native: 'ދިވެހިބަސް',
    it: 'Divehi',
  },
  dz: {
    '639-1': 'dz',
    '639-2': 'dzo',
    key: 'dzongkha',
    en: 'Dzongkha',
    fr: 'Dzongkha',
    de: 'Dzongkha',
    native: 'རྫོང་ཁ',
    it: 'Dzongkha',
  },
  ee: {
    '639-1': 'ee',
    '639-2': 'ewe',
    key: 'ewe',
    en: 'Ewe',
    fr: 'Éwé',
    de: 'Ewe-Sprache',
    native: 'Ɛʋɛ',
    it: 'Ewe',
  },
  el: {
    '639-1': 'el',
    '639-2': 'ell',
    key: 'greek',
    en: 'Greek',
    fr: 'Grec',
    de: 'Neugriechisch',
    native: 'Ελληνικά',
    it: 'Greco',
  },
  en: {
    '639-1': 'en',
    '639-2': 'eng',
    key: 'english',
    en: 'English',
    fr: 'Anglais',
    de: 'Englisch',
    native: 'English',
    it: 'Inglese',
  },
  eo: {
    '639-1': 'eo',
    '639-2': 'epo',
    key: 'esperanto',
    en: 'Esperanto',
    fr: 'Espéranto',
    de: 'Esperanto',
    native: 'Esperanto',
    it: 'Esperanto',
  },
  es: {
    '639-1': 'es',
    '639-2': 'spa',
    key: 'spanish',
    en: 'Spanish',
    fr: 'Espagnol',
    de: 'Spanisch',
    native: 'Español',
    it: 'Spagnolo',
  },
  et: {
    '639-1': 'et',
    '639-2': 'est',
    key: 'estonian',
    en: 'Estonian',
    fr: 'Estonien',
    de: 'Estnisch',
    native: 'Eesti',
    it: 'Estone',
  },
  eu: {
    '639-1': 'eu',
    '639-2': 'eus',
    key: 'basque',
    en: 'Basque',
    fr: 'Basque',
    de: 'Baskisch',
    native: 'Euskara',
    it: 'Basco',
  },
  fa: {
    '639-1': 'fa',
    '639-2': 'fas',
    key: 'persian',
    en: 'Persian',
    fr: 'Persan',
    de: 'Persisch',
    native: 'فارسی',
    it: 'Persiano',
  },
  ff: {
    '639-1': 'ff',
    '639-2': 'ful',
    key: 'fulah',
    en: 'Fulah',
    fr: 'Peul',
    de: 'Ful',
    native: 'Fulfulde',
    it: 'Fulah',
  },
  fi: {
    '639-1': 'fi',
    '639-2': 'fin',
    key: 'finnish',
    en: 'Finnish',
    fr: 'Finnois',
    de: 'Finnisch',
    native: 'Suomi',
    it: 'Finlandese',
  },
  fj: {
    '639-1': 'fj',
    '639-2': 'fij',
    key: 'fijian',
    en: 'Fijian',
    fr: 'Fidjien',
    de: 'Fidschi-Sprache',
    native: 'Na',
    it: 'Figiano',
  },
  fo: {
    '639-1': 'fo',
    '639-2': 'fao',
    key: 'faroese',
    en: 'Faroese',
    fr: 'Féroïen',
    de: 'Färöisch',
    native: 'Føroyskt',
    it: 'Faroese',
  },
  fr: {
    '639-1': 'fr',
    '639-2': 'fra',
    key: 'french',
    en: 'French',
    fr: 'Français',
    de: 'Französisch',
    native: 'Français',
    it: 'Francese',
  },
  fy: {
    '639-1': 'fy',
    '639-2': 'fry',
    key: 'western',
    en: 'Western',
    fr: 'Frison',
    de: 'Friesisch',
    native: 'Frysk',
    it: 'Occidentale',
  },
  ga: {
    '639-1': 'ga',
    '639-2': 'gle',
    key: 'irish',
    en: 'Irish',
    fr: 'Irlandais',
    de: 'Irisch',
    native: 'Gaeilge',
    it: 'Irlandese',
  },
  gd: {
    '639-1': 'gd',
    '639-2': 'gla',
    key: 'gaelic',
    en: 'Gaelic',
    fr: 'Gaélique',
    de: 'Gälisch-Schottisch',
    native: 'Gàidhlig',
    it: 'Gaelico',
  },
  gl: {
    '639-1': 'gl',
    '639-2': 'glg',
    key: 'galician',
    en: 'Galician',
    fr: 'Galicien',
    de: 'Galicisch',
    native: 'Galego',
    it: 'Galiziano',
  },
  gn: {
    '639-1': 'gn',
    '639-2': 'grn',
    key: 'guarani',
    en: 'Guarani',
    fr: 'Guarani',
    de: 'Guaraní-Sprache',
    native: "Avañe'ẽ",
    it: 'Guarani',
  },
  gu: {
    '639-1': 'gu',
    '639-2': 'guj',
    key: 'gujarati',
    en: 'Gujarati',
    fr: 'Goudjrati',
    de: 'Gujarati-Sprache',
    native: 'ગુજરાતી',
    it: 'Gujarati',
  },
  gv: {
    '639-1': 'gv',
    '639-2': 'glv',
    key: 'manx',
    en: 'Manx',
    fr: 'Manx',
    de: 'Manx',
    native: 'Gaelg',
    it: 'Manx',
  },
  ha: {
    '639-1': 'ha',
    '639-2': 'hau',
    key: 'hausa',
    en: 'Hausa',
    fr: 'Haoussa',
    de: 'Haussa-Sprache',
    native: 'هَوُسَ',
    it: 'Hausa',
  },
  he: {
    '639-1': 'he',
    '639-2': 'heb',
    key: 'hebrew',
    en: 'Hebrew',
    fr: 'Hébreu',
    de: 'Hebräisch',
    native: 'עברית',
    it: 'Ebraico',
  },
  hi: {
    '639-1': 'hi',
    '639-2': 'hin',
    key: 'hindi',
    en: 'Hindi',
    fr: 'Hindi',
    de: 'Hindi',
    native: 'हिन्दी',
    it: 'Hindi',
  },
  ho: {
    '639-1': 'ho',
    '639-2': 'hmo',
    key: 'hiri',
    en: 'Hiri',
    fr: 'Hiri',
    de: 'Hiri-Motu',
    native: 'Hiri',
    it: 'Hiri',
  },
  hr: {
    '639-1': 'hr',
    '639-2': 'hrv',
    key: 'croatian',
    en: 'Croatian',
    fr: 'Croate',
    de: 'Kroatisch',
    native: 'Hrvatski',
    it: 'Croato',
  },
  ht: {
    '639-1': 'ht',
    '639-2': 'hat',
    key: 'haitian',
    en: 'Haitian',
    fr: 'Haïtien',
    de: 'Haïtien',
    native: 'Krèyol',
    it: 'Haitiano',
  },
  hu: {
    '639-1': 'hu',
    '639-2': 'hun',
    key: 'hungarian',
    en: 'Hungarian',
    fr: 'Hongrois',
    de: 'Ungarisch',
    native: 'Magyar',
    it: 'Ungherese',
  },
  hy: {
    '639-1': 'hy',
    '639-2': 'hye',
    key: 'armenian',
    en: 'Armenian',
    fr: 'Arménien',
    de: 'Armenisch',
    native: 'Հայերեն',
    it: 'Armeno',
  },
  hz: {
    '639-1': 'hz',
    '639-2': 'her',
    key: 'herero',
    en: 'Herero',
    fr: 'Herero',
    de: 'Herero-Sprache',
    native: 'Otsiherero',
    it: 'Herero',
  },
  ia: {
    '639-1': 'ia',
    '639-2': 'ina',
    key: 'interlingua',
    en: 'Interlingua',
    fr: 'Interlingua',
    de: 'Interlingua',
    native: 'Interlingua',
    it: 'Interlingua',
  },
  id: {
    '639-1': 'id',
    '639-2': 'ind',
    key: 'indonesian',
    en: 'Indonesian',
    fr: 'Indonésien',
    de: 'Bahasa',
    native: 'Bahasa',
    it: 'Indonesiano',
  },
  ie: {
    '639-1': 'ie',
    '639-2': 'ile',
    key: 'interlingue',
    en: 'Interlingue',
    fr: 'Interlingue',
    de: 'Interlingue',
    native: 'Interlingue',
    it: 'Interlingua',
  },
  ig: {
    '639-1': 'ig',
    '639-2': 'ibo',
    key: 'igbo',
    en: 'Igbo',
    fr: 'Igbo',
    de: 'Ibo-Sprache',
    native: 'Igbo',
    it: 'Igbo',
  },
  ii: {
    '639-1': 'ii',
    '639-2': 'iii',
    key: 'sichuan',
    en: 'Sichuan',
    fr: 'Yi',
    de: 'Lalo-Sprache',
    native: 'ꆇꉙ',
    it: 'Sichuan',
  },
  ik: {
    '639-1': 'ik',
    '639-2': 'ipk',
    key: 'inupiaq',
    en: 'Inupiaq',
    fr: 'Inupiaq',
    de: 'Inupik',
    native: 'Iñupiak',
    it: 'Inupiaq',
  },
  io: {
    '639-1': 'io',
    '639-2': 'ido',
    key: 'ido',
    en: 'Ido',
    fr: 'Ido',
    de: 'Ido',
    native: 'Ido',
    it: 'Ido',
  },
  is: {
    '639-1': 'is',
    '639-2': 'isl',
    key: 'icelandic',
    en: 'Icelandic',
    fr: 'Islandais',
    de: 'Isländisch',
    native: 'Íslenska',
    it: 'Islandese',
  },
  it: {
    '639-1': 'it',
    '639-2': 'ita',
    key: 'italian',
    en: 'Italian',
    fr: 'Italien',
    de: 'Italienisch',
    native: 'Italiano',
    it: 'Italiano',
  },
  iu: {
    '639-1': 'iu',
    '639-2': 'iku',
    key: 'inuktitut',
    en: 'Inuktitut',
    fr: 'Inuktitut',
    de: 'Inuktitut',
    native: 'ᐃᓄᒃᑎᑐᑦ',
    it: 'Inuktitut',
  },
  ja: {
    '639-1': 'ja',
    '639-2': 'jpn',
    key: 'japanese',
    en: 'Japanese',
    fr: 'Japonais',
    de: 'Japanisch',
    native: '日本語',
    it: 'Giapponese',
  },
  jv: {
    '639-1': 'jv',
    '639-2': 'jav',
    key: 'javanese',
    en: 'Javanese',
    fr: 'Javanais',
    de: 'Javanisch',
    native: 'Basa',
    it: 'Giavanese',
  },
  ka: {
    '639-1': 'ka',
    '639-2': 'kat',
    key: 'georgian',
    en: 'Georgian',
    fr: 'Géorgien',
    de: 'Georgisch',
    native: 'Ქართული',
    it: 'Georgiano',
  },
  kg: {
    '639-1': 'kg',
    '639-2': 'kon',
    key: 'kongo',
    en: 'Kongo',
    fr: 'Kongo',
    de: 'Kongo-Sprache',
    native: 'KiKongo',
    it: 'Kongo',
  },
  ki: {
    '639-1': 'ki',
    '639-2': 'kik',
    key: 'kikuyu',
    en: 'Kikuyu',
    fr: 'Kikuyu',
    de: 'Kikuyu-Sprache',
    native: 'Gĩkũyũ',
    it: 'Kikuyu',
  },
  kj: {
    '639-1': 'kj',
    '639-2': 'kua',
    key: 'kuanyama',
    en: 'Kuanyama',
    fr: 'Kuanyama',
    de: 'Kwanyama-Sprache',
    native: 'Kuanyama',
    it: 'Kuanyama',
  },
  kk: {
    '639-1': 'kk',
    '639-2': 'kaz',
    key: 'kazakh',
    en: 'Kazakh',
    fr: 'Kazakh',
    de: 'Kasachisch',
    native: 'Қазақша',
    it: 'Kazako',
  },
  kl: {
    '639-1': 'kl',
    '639-2': 'kal',
    key: 'kalaallisut',
    en: 'Kalaallisut',
    fr: 'Groenlandais',
    de: 'Grönländisch',
    native: 'Kalaallisut',
    it: 'Kalaallisut',
  },
  km: {
    '639-1': 'km',
    '639-2': 'khm',
    key: 'central',
    en: 'Central',
    fr: 'Khmer',
    de: 'Kambodschanisch',
    native: 'ភាសាខ្មែរ',
    it: 'Centrale',
  },
  kn: {
    '639-1': 'kn',
    '639-2': 'kan',
    key: 'kannada',
    en: 'Kannada',
    fr: 'Kannada',
    de: 'Kannada',
    native: 'ಕನ್ನಡ',
    it: 'Kannada',
  },
  ko: {
    '639-1': 'ko',
    '639-2': 'kor',
    key: 'korean',
    en: 'Korean',
    fr: 'Coréen',
    de: 'Koreanisch',
    native: '한국어',
    it: 'Coreano',
  },
  kr: {
    '639-1': 'kr',
    '639-2': 'kau',
    key: 'kanuri',
    en: 'Kanuri',
    fr: 'Kanouri',
    de: 'Kanuri-Sprache',
    native: 'Kanuri',
    it: 'Kanuri',
  },
  ks: {
    '639-1': 'ks',
    '639-2': 'kas',
    key: 'kashmiri',
    en: 'Kashmiri',
    fr: 'Kashmiri',
    de: 'Kaschmiri',
    native: 'कॉशुर',
    it: 'Kashmiri',
  },
  ku: {
    '639-1': 'ku',
    '639-2': 'kur',
    key: 'kurdish',
    en: 'Kurdish',
    fr: 'Kurde',
    de: 'Kurdisch',
    native: 'Kurdî',
    it: 'Curdo',
  },
  kv: {
    '639-1': 'kv',
    '639-2': 'kom',
    key: 'komi',
    en: 'Komi',
    fr: 'Kom',
    de: 'Komi-Sprache',
    native: 'Коми',
    it: 'Komi',
  },
  kw: {
    '639-1': 'kw',
    '639-2': 'cor',
    key: 'cornish',
    en: 'Cornish',
    fr: 'Cornique',
    de: 'Kornisch',
    native: 'Kernewek',
    it: 'Cornico',
  },
  ky: {
    '639-1': 'ky',
    '639-2': 'kir',
    key: 'kirghiz',
    en: 'Kirghiz',
    fr: 'Kirghiz',
    de: 'Kirgisisch',
    native: 'Kırgızca',
    it: 'Kirghiz',
  },
  la: {
    '639-1': 'la',
    '639-2': 'lat',
    key: 'latin',
    en: 'Latin',
    fr: 'Latin',
    de: 'Latein',
    native: 'Latina',
    it: 'Latino',
  },
  lb: {
    '639-1': 'lb',
    '639-2': 'ltz',
    key: 'luxembourgish',
    en: 'Luxembourgish',
    fr: 'Luxembourgeois',
    de: 'Luxemburgisch',
    native: 'Lëtzebuergesch',
    it: 'Lussemburghese',
  },
  lg: {
    '639-1': 'lg',
    '639-2': 'lug',
    key: 'ganda',
    en: 'Ganda',
    fr: 'Ganda',
    de: 'Ganda-Sprache',
    native: 'Luganda',
    it: 'Ganda',
  },
  li: {
    '639-1': 'li',
    '639-2': 'lim',
    key: 'limburgan',
    en: 'Limburgan',
    fr: 'Limbourgeois',
    de: 'Limburgisch',
    native: 'Limburgs',
    it: 'Limburghese',
  },
  ln: {
    '639-1': 'ln',
    '639-2': 'lin',
    key: 'lingala',
    en: 'Lingala',
    fr: 'Lingala',
    de: 'Lingala',
    native: 'Lingála',
    it: 'Lingala',
  },
  lo: {
    '639-1': 'lo',
    '639-2': 'lao',
    key: 'lao',
    en: 'Lao',
    fr: 'Lao',
    de: 'Laotisch',
    native: 'ລາວ',
    it: 'Lao',
  },
  lt: {
    '639-1': 'lt',
    '639-2': 'lit',
    key: 'lithuanian',
    en: 'Lithuanian',
    fr: 'Lituanien',
    de: 'Litauisch',
    native: 'Lietuvių',
    it: 'Lituano',
  },
  lv: {
    '639-1': 'lv',
    '639-2': 'lav',
    key: 'latvian',
    en: 'Latvian',
    fr: 'Letton',
    de: 'Lettisch',
    native: 'Latviešu',
    it: 'Lettone',
  },
  mg: {
    '639-1': 'mg',
    '639-2': 'mlg',
    key: 'malagasy',
    en: 'Malagasy',
    fr: 'Malgache',
    de: 'Malagassi-Sprache',
    native: 'Malagasy',
    it: 'Malgascio',
  },
  mh: {
    '639-1': 'mh',
    '639-2': 'mah',
    key: 'marshallese',
    en: 'Marshallese',
    fr: 'Marshall',
    de: 'Marschallesisch',
    native: 'Kajin',
    it: 'Marshallese',
  },
  mi: {
    '639-1': 'mi',
    '639-2': 'mri',
    key: 'maori',
    en: 'Maori',
    fr: 'Maori',
    de: 'Maori-Sprache',
    native: 'Māori',
    it: 'Maori',
  },
  mk: {
    '639-1': 'mk',
    '639-2': 'mkd',
    key: 'macedonian',
    en: 'Macedonian',
    fr: 'Macédonien',
    de: 'Makedonisch',
    native: 'Македонски',
    it: 'Macedone',
  },
  ml: {
    '639-1': 'ml',
    '639-2': 'mal',
    key: 'malayalam',
    en: 'Malayalam',
    fr: 'Malayalam',
    de: 'Malayalam',
    native: 'മലയാളം',
    it: 'Malayalam',
  },
  mn: {
    '639-1': 'mn',
    '639-2': 'mon',
    key: 'mongolian',
    en: 'Mongolian',
    fr: 'Mongol',
    de: 'Mongolisch',
    native: 'Монгол',
    it: 'Mongolo',
  },
  mr: {
    '639-1': 'mr',
    '639-2': 'mar',
    key: 'marathi',
    en: 'Marathi',
    fr: 'Marathe',
    de: 'Marathi',
    native: 'मराठी',
    it: 'Marathi',
  },
  ms: {
    '639-1': 'ms',
    '639-2': 'msa',
    key: 'malay',
    en: 'Malay',
    fr: 'Malais',
    de: 'Malaiisch',
    native: 'Bahasa',
    it: 'Malese',
  },
  mt: {
    '639-1': 'mt',
    '639-2': 'mlt',
    key: 'maltese',
    en: 'Maltese',
    fr: 'Maltais',
    de: 'Maltesisch',
    native: 'Bil-Malti',
    it: 'Maltese',
  },
  mu: {
    '639-1': 'mu',
    '639-2': 'mul',
    key: 'multilingual',
    en: 'Multilingual',
    fr: 'Multilangue',
    de: 'Mehrsprachig',
    native: 'Multilingual',
    it: 'Multilingue',
  },
  my: {
    '639-1': 'my',
    '639-2': 'mya',
    key: 'burmese',
    en: 'Burmese',
    fr: 'Birman',
    de: 'Birmanisch',
    native: 'Myanmasa',
    it: 'Birmano',
  },
  na: {
    '639-1': 'na',
    '639-2': 'nau',
    key: 'nauru',
    en: 'Nauru',
    fr: 'Nauruan',
    de: 'Nauruanisch',
    native: 'Dorerin',
    it: 'Nauru',
  },
  nb: {
    '639-1': 'nb',
    '639-2': 'nob',
    key: 'bokmål',
    en: 'Bokmål',
    fr: 'Norvégien',
    de: 'Bokmål',
    native: 'Norsk',
    it: 'Bokmål,',
  },
  nd: {
    '639-1': 'nd',
    '639-2': 'nde',
    key: 'ndebele',
    en: 'Ndebele',
    fr: 'Ndébélé',
    de: 'Ndebele-Sprache',
    native: 'Sindebele',
    it: 'Ndebele,',
  },
  ne: {
    '639-1': 'ne',
    '639-2': 'nep',
    key: 'nepali',
    en: 'Nepali',
    fr: 'Népalais',
    de: 'Nepali',
    native: 'नेपाली',
    it: 'Nepalese',
  },
  ng: {
    '639-1': 'ng',
    '639-2': 'ndo',
    key: 'ndonga',
    en: 'Ndonga',
    fr: 'Ndonga',
    de: 'Ndonga',
    native: 'Oshiwambo',
    it: 'Ndonga',
  },
  nl: {
    '639-1': 'nl',
    '639-2': 'nld',
    key: 'dutch',
    en: 'Dutch',
    fr: 'Néerlandais',
    de: 'Niederländisch',
    native: 'Nederlands',
    it: 'Olandese',
  },
  nn: {
    '639-1': 'nn',
    '639-2': 'nno',
    key: 'norwegian',
    en: 'Norwegian',
    fr: 'Norvégien',
    de: 'Nynorsk',
    native: 'Norsk',
    it: 'Norvegese',
  },
  no: {
    '639-1': 'no',
    '639-2': 'nor',
    key: 'norwegian',
    en: 'Norwegian',
    fr: 'Norvégien',
    de: 'Norwegisch',
    native: 'Norsk',
    it: 'Norvegese',
  },
  nr: {
    '639-1': 'nr',
    '639-2': 'nbl',
    key: 'ndebele',
    en: 'Ndebele',
    fr: 'Ndébélé',
    de: 'Ndebele-Sprache',
    native: 'IsiNdebele',
    it: 'Ndebele',
  },
  nv: {
    '639-1': 'nv',
    '639-2': 'nav',
    key: 'navajo',
    en: 'Navajo',
    fr: 'Navaho',
    de: 'Navajo-Sprache',
    native: 'Diné',
    it: 'Navajo',
  },
  ny: {
    '639-1': 'ny',
    '639-2': 'nya',
    key: 'chichewa',
    en: 'Chichewa',
    fr: 'Chichewa',
    de: 'Nyanja-Sprache',
    native: 'Chi-Chewa',
    it: 'Chichewa',
  },
  oc: {
    '639-1': 'oc',
    '639-2': 'oci',
    key: 'occitan',
    en: 'Occitan',
    fr: 'Occitan',
    de: 'Okzitanisch',
    native: 'Occitan',
    it: 'Occitano',
  },
  oj: {
    '639-1': 'oj',
    '639-2': 'oji',
    key: 'ojibwa',
    en: 'Ojibwa',
    fr: 'Ojibwa',
    de: 'Ojibwa-Sprache',
    native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    it: 'Ojibwa',
  },
  om: {
    '639-1': 'om',
    '639-2': 'orm',
    key: 'oromo',
    en: 'Oromo',
    fr: 'Galla',
    de: 'Galla-Sprache',
    native: 'Oromoo',
    it: 'Oromo',
  },
  or: {
    '639-1': 'or',
    '639-2': 'ori',
    key: 'oriya',
    en: 'Oriya',
    fr: 'Oriya',
    de: 'Oriya-Sprache',
    native: 'ଓଡ଼ିଆ',
    it: 'Oriya',
  },
  os: {
    '639-1': 'os',
    '639-2': 'oss',
    key: 'ossetian',
    en: 'Ossetian',
    fr: 'Ossète',
    de: 'Ossetisch',
    native: 'Иронау',
    it: 'Osseto',
  },
  pa: {
    '639-1': 'pa',
    '639-2': 'pan',
    key: 'panjabi',
    en: 'Panjabi',
    fr: 'Pendjabi',
    de: 'Pandschabi-Sprache',
    native: 'ਪੰਜਾਬੀ',
    it: 'Panjabi',
  },
  pi: {
    '639-1': 'pi',
    '639-2': 'pli',
    key: 'pali',
    en: 'Pali',
    fr: 'Pali',
    de: 'Pali',
    native: 'Pāli',
    it: 'Pali',
  },
  pl: {
    '639-1': 'pl',
    '639-2': 'pol',
    key: 'polish',
    en: 'Polish',
    fr: 'Polonais',
    de: 'Polnisch',
    native: 'Polski',
    it: 'Polacco',
  },
  ps: {
    '639-1': 'ps',
    '639-2': 'pus',
    key: 'pushto',
    en: 'Pushto',
    fr: 'Pachto',
    de: 'Paschtu',
    native: 'پښتو',
    it: 'Pushto',
  },
  pt: {
    '639-1': 'pt',
    '639-2': 'por',
    key: 'portuguese',
    en: 'Portuguese',
    fr: 'Portugais',
    de: 'Portugiesisch',
    native: 'Português',
    it: 'Portoghese',
  },
  qu: {
    '639-1': 'qu',
    '639-2': 'que',
    key: 'quechua',
    en: 'Quechua',
    fr: 'Quechua',
    de: 'Quechua-Sprache',
    native: 'Runa',
    it: 'Quechua',
  },
  rm: {
    '639-1': 'rm',
    '639-2': 'roh',
    key: 'romansh',
    en: 'Romansh',
    fr: 'Romanche',
    de: 'Rätoromanisch',
    native: 'Rumantsch',
    it: 'Romancio',
  },
  rn: {
    '639-1': 'rn',
    '639-2': 'run',
    key: 'rundi',
    en: 'Rundi',
    fr: 'Rundi',
    de: 'Rundi-Sprache',
    native: 'Kirundi',
    it: 'Rundi',
  },
  ro: {
    '639-1': 'ro',
    '639-2': 'ron',
    key: 'romanian',
    en: 'Romanian',
    fr: 'Roumain',
    de: 'Rumänisch',
    native: 'Română',
    it: 'Rumeno',
  },
  ru: {
    '639-1': 'ru',
    '639-2': 'rus',
    key: 'russian',
    en: 'Russian',
    fr: 'Russe',
    de: 'Russisch',
    native: 'Русский',
    it: 'Russo',
  },
  rw: {
    '639-1': 'rw',
    '639-2': 'kin',
    key: 'kinyarwanda',
    en: 'Kinyarwanda',
    fr: 'Rwanda',
    de: 'Rwanda-Sprache',
    native: 'Kinyarwandi',
    it: 'Kinyarwanda',
  },
  sa: {
    '639-1': 'sa',
    '639-2': 'san',
    key: 'sanskrit',
    en: 'Sanskrit',
    fr: 'Sanskrit',
    de: 'Sanskrit',
    native: 'संस्कृतम्',
    it: 'Sanscrito',
  },
  sc: {
    '639-1': 'sc',
    '639-2': 'srd',
    key: 'sardinian',
    en: 'Sardinian',
    fr: 'Sarde',
    de: 'Sardisch',
    native: 'Sardu',
    it: 'Sardo',
  },
  sd: {
    '639-1': 'sd',
    '639-2': 'snd',
    key: 'sindhi',
    en: 'Sindhi',
    fr: 'Sindhi',
    de: 'Sindhi-Sprache',
    native: 'सिंधी',
    it: 'Sindhi',
  },
  se: {
    '639-1': 'se',
    '639-2': 'sme',
    key: 'northern',
    en: 'Northern',
    fr: 'Sami',
    de: 'Nordsaamisch',
    native: 'Davvisámegiella',
    it: 'Nord',
  },
  sg: {
    '639-1': 'sg',
    '639-2': 'sag',
    key: 'sango',
    en: 'Sango',
    fr: 'Sango',
    de: 'Sango-Sprache',
    native: 'Sängö',
    it: 'Sango',
  },
  si: {
    '639-1': 'si',
    '639-2': 'sin',
    key: 'sinhala',
    en: 'Sinhala',
    fr: 'Singhalais',
    de: 'Singhalesisch',
    native: 'සිංහල',
    it: 'Sinhala',
  },
  sk: {
    '639-1': 'sk',
    '639-2': 'slk',
    key: 'slovak',
    en: 'Slovak',
    fr: 'Slovaque',
    de: 'Slowakisch',
    native: 'Slovenčina',
    it: 'Slovacco',
  },
  sl: {
    '639-1': 'sl',
    '639-2': 'slv',
    key: 'slovenian',
    en: 'Slovenian',
    fr: 'Slovène',
    de: 'Slowenisch',
    native: 'Slovenščina',
    it: 'Sloveno',
  },
  sm: {
    '639-1': 'sm',
    '639-2': 'smo',
    key: 'samoan',
    en: 'Samoan',
    fr: 'Samoan',
    de: 'Samoanisch',
    native: 'Gagana',
    it: 'Samoano',
  },
  sn: {
    '639-1': 'sn',
    '639-2': 'sna',
    key: 'shona',
    en: 'Shona',
    fr: 'Shona',
    de: 'Schona-Sprache',
    native: 'ChiShona',
    it: 'Shona',
  },
  so: {
    '639-1': 'so',
    '639-2': 'som',
    key: 'somali',
    en: 'Somali',
    fr: 'Somali',
    de: 'Somali',
    native: 'Soomaaliga',
    it: 'Somalo',
  },
  sq: {
    '639-1': 'sq',
    '639-2': 'sqi',
    key: 'albanian',
    en: 'Albanian',
    fr: 'Albanais',
    de: 'Albanisch',
    native: 'Shqip',
    it: 'Albanese',
  },
  sr: {
    '639-1': 'sr',
    '639-2': 'srp',
    key: 'serbian',
    en: 'Serbian',
    fr: 'Serbe',
    de: 'Serbisch',
    native: 'Српски',
    it: 'Serbo',
  },
  ss: {
    '639-1': 'ss',
    '639-2': 'ssw',
    key: 'swati',
    en: 'Swati',
    fr: 'Swati',
    de: 'Swasi-Sprache',
    native: 'SiSwati',
    it: 'Swati',
  },
  st: {
    '639-1': 'st',
    '639-2': 'sot',
    key: 'sotho',
    en: 'Sotho',
    fr: 'Sotho',
    de: 'Süd-Sotho-Sprache',
    native: 'Sesotho',
    it: 'Sotho',
  },
  su: {
    '639-1': 'su',
    '639-2': 'sun',
    key: 'sundanese',
    en: 'Sundanese',
    fr: 'Soundanais',
    de: 'Sundanesisch',
    native: 'Basa',
    it: 'Sundanese',
  },
  sv: {
    '639-1': 'sv',
    '639-2': 'swe',
    key: 'swedish',
    en: 'Swedish',
    fr: 'Suédois',
    de: 'Schwedisch',
    native: 'Svenska',
    it: 'Svedese',
  },
  sw: {
    '639-1': 'sw',
    '639-2': 'swa',
    key: 'swahili',
    en: 'Swahili',
    fr: 'Swahili',
    de: 'Swahili',
    native: 'Kiswahili',
    it: 'Swahili',
  },
  ta: {
    '639-1': 'ta',
    '639-2': 'tam',
    key: 'tamil',
    en: 'Tamil',
    fr: 'Tamoul',
    de: 'Tamil',
    native: 'தமிழ்',
    it: 'Tamil',
  },
  te: {
    '639-1': 'te',
    '639-2': 'tel',
    key: 'telugu',
    en: 'Telugu',
    fr: 'Télougou',
    de: 'Telugu-Sprache',
    native: 'తెలుగు',
    it: 'Telugu',
  },
  tg: {
    '639-1': 'tg',
    '639-2': 'tgk',
    key: 'tajik',
    en: 'Tajik',
    fr: 'Tadjik',
    de: 'Tadschikisch',
    native: 'Тоҷикӣ',
    it: 'Tajik',
  },
  th: {
    '639-1': 'th',
    '639-2': 'tha',
    key: 'thai',
    en: 'Thai',
    fr: 'Thaï',
    de: 'Thailändisch',
    native: 'ไทย',
    it: 'Thai',
  },
  ti: {
    '639-1': 'ti',
    '639-2': 'tir',
    key: 'tigrinya',
    en: 'Tigrinya',
    fr: 'Tigrigna',
    de: 'Tigrinja-Sprache',
    native: 'ትግርኛ',
    it: 'Tigrino',
  },
  tk: {
    '639-1': 'tk',
    '639-2': 'tuk',
    key: 'turkmen',
    en: 'Turkmen',
    fr: 'Turkmène',
    de: 'Turkmenisch',
    native: 'Туркмен',
    it: 'Turkmeno',
  },
  tl: {
    '639-1': 'tl',
    '639-2': 'tgl',
    key: 'tagalog',
    en: 'Tagalog',
    fr: 'Tagalog',
    de: 'Tagalog',
    native: 'Tagalog',
    it: 'Tagalog',
  },
  tn: {
    '639-1': 'tn',
    '639-2': 'tsn',
    key: 'tswana',
    en: 'Tswana',
    fr: 'Tswana',
    de: 'Tswana-Sprache',
    native: 'Setswana',
    it: 'Tswana',
  },
  to: {
    '639-1': 'to',
    '639-2': 'ton',
    key: 'tonga',
    en: 'Tonga',
    fr: 'Tongan',
    de: 'Tongaisch',
    native: 'Lea',
    it: 'Tonga',
  },
  tr: {
    '639-1': 'tr',
    '639-2': 'tur',
    key: 'turkish',
    en: 'Turkish',
    fr: 'Turc',
    de: 'Türkisch',
    native: 'Türkçe',
    it: 'Turco',
  },
  ts: {
    '639-1': 'ts',
    '639-2': 'tso',
    key: 'tsonga',
    en: 'Tsonga',
    fr: 'Tsonga',
    de: 'Tsonga-Sprache',
    native: 'Xitsonga',
    it: 'Tsonga',
  },
  tt: {
    '639-1': 'tt',
    '639-2': 'tat',
    key: 'tatar',
    en: 'Tatar',
    fr: 'Tatar',
    de: 'Tatarisch',
    native: 'Tatarça',
    it: 'Tatar',
  },
  tw: {
    '639-1': 'tw',
    '639-2': 'twi',
    key: 'twi',
    en: 'Twi',
    fr: 'Twi',
    de: 'Twi-Sprache',
    native: 'Twi',
    it: 'Twi',
  },
  ty: {
    '639-1': 'ty',
    '639-2': 'tah',
    key: 'tahitian',
    en: 'Tahitian',
    fr: 'Tahitien',
    de: 'Tahitisch',
    native: 'Reo',
    it: 'Tahitiano',
  },
  ug: {
    '639-1': 'ug',
    '639-2': 'uig',
    key: 'uighur',
    en: 'Uighur',
    fr: 'Ouïgour',
    de: 'Uigurisch',
    native: 'Uyƣurqə',
    it: 'Uighur',
  },
  uk: {
    '639-1': 'uk',
    '639-2': 'ukr',
    key: 'ukrainian',
    en: 'Ukrainian',
    fr: 'Ukrainien',
    de: 'Ukrainisch',
    native: 'Українська',
    it: 'Ucraino',
  },
  ur: {
    '639-1': 'ur',
    '639-2': 'urd',
    key: 'urdu',
    en: 'Urdu',
    fr: 'Ourdou',
    de: 'Urdu',
    native: 'اردو',
    it: 'Urdu',
  },
  uz: {
    '639-1': 'uz',
    '639-2': 'uzb',
    key: 'uzbek',
    en: 'Uzbek',
    fr: 'Ouszbek',
    de: 'Usbekisch',
    native: 'Ўзбек',
    it: 'Uzbek',
  },
  ve: {
    '639-1': 've',
    '639-2': 'ven',
    key: 'venda',
    en: 'Venda',
    fr: 'Venda',
    de: 'Venda-Sprache',
    native: 'Tshivenḓa',
    it: 'Venda',
  },
  vi: {
    '639-1': 'vi',
    '639-2': 'vie',
    key: 'vietnamese',
    en: 'Vietnamese',
    fr: 'Vietnamien',
    de: 'Vietnamesisch',
    native: 'Tiếng',
    it: 'Vietnamita',
  },
  vo: {
    '639-1': 'vo',
    '639-2': 'vol',
    key: 'volapük',
    en: 'Volapük',
    fr: 'Volapük',
    de: 'Volapük',
    native: 'Volapük',
    it: 'Volapük',
  },
  wa: {
    '639-1': 'wa',
    '639-2': 'wln',
    key: 'walloon',
    en: 'Walloon',
    fr: 'Wallon',
    de: 'Wallonisch',
    native: 'Walon',
    it: 'Vallone',
  },
  wo: {
    '639-1': 'wo',
    '639-2': 'wol',
    key: 'wolof',
    en: 'Wolof',
    fr: 'Wolof',
    de: 'Wolof-Sprache',
    native: 'Wollof',
    it: 'Wolof',
  },
  xh: {
    '639-1': 'xh',
    '639-2': 'xho',
    key: 'xhosa',
    en: 'Xhosa',
    fr: 'Xhosa',
    de: 'Xhosa-Sprache',
    native: 'IsiXhosa',
    it: 'Xhosa',
  },
  yi: {
    '639-1': 'yi',
    '639-2': 'yid',
    key: 'yiddish',
    en: 'Yiddish',
    fr: 'Yiddish',
    de: 'Jiddisch',
    native: 'ייִדיש',
    it: 'Yiddish',
  },
  yo: {
    '639-1': 'yo',
    '639-2': 'yor',
    key: 'yoruba',
    en: 'Yoruba',
    fr: 'Yoruba',
    de: 'Yoruba-Sprache',
    native: 'Yorùbá',
    it: 'Yoruba',
  },
  za: {
    '639-1': 'za',
    '639-2': 'zha',
    key: 'zhuang',
    en: 'Zhuang',
    fr: 'Zhuang',
    de: 'Zhuang',
    native: 'Cuengh',
    it: 'Zhuang',
  },
  zh: {
    '639-1': 'zh',
    '639-2': 'zho',
    key: 'chinese',
    en: 'Chinese',
    fr: 'Chinois',
    de: 'Chinesisch',
    native: '中文',
    it: 'Cinese',
  },
  zu: {
    '639-1': 'zu',
    '639-2': 'zul',
    key: 'zulu',
    en: 'Zulu',
    fr: 'Zoulou',
    de: 'Zulu-Sprache',
    native: 'IsiZulu',
    it: 'Zulu',
  },
  zx: {
    '639-1': 'zx',
    '639-2': 'zxx',
    key: 'nolinguisticcontent',
    en: 'No linguistic content',
    fr: 'Pas de contenu linguistique',
    de: 'Kein sprachlicher Inhalt',
    native: 'No linguistic content',
    it: 'Nessun contenuto linguistico',
  },
};

export const ALL_LANGUAGES = Object.values(LANGUAGE_BY_CODE);

type LanguagesInLocale = { [key in typeof ALL_LANGUAGES[number]['639-1']]: string };
export const getLanguagesInLocale = (locale: keyof typeof ALL_LANGUAGES[number]) =>
  ALL_LANGUAGES.reduce<LanguagesInLocale>(
    (arr, l) => ({
      ...arr,
      [l['639-1']]: l[locale] || l.de,
    }),
    {} as LanguagesInLocale
  );

export const EXTRA_AUDIO_LANGUAGES = [
  { code: 'fr_ca', en: 'French', de: 'Französisch', fr: 'Français', it: 'Francese' },
  { code: 'teo', en: 'French AD', de: 'Französisch AD', fr: 'Français AD', it: 'Francese AD' },
  { code: 'fr_ad', en: 'French AD', de: 'Französisch AD', fr: 'Français AD', it: 'Francese AD' },
  { code: 'hkk', en: 'German AD', de: 'Deutsch AD', fr: 'Allemand AD', it: 'Tedesco AD' },
  { code: 'de_ad', en: 'German AD', de: 'Deutsch AD', fr: 'Allemand AD', it: 'Tedesco AD' },
  { code: 'hnn', en: 'Italian AD', de: 'Italienisch AD', fr: 'Italien AD', it: 'Italiano AD' },
  { code: 'it_ad', en: 'Italian AD', de: 'Italienisch AD', fr: 'Italien AD', it: 'Italiano AD' },
  { code: 'hak', en: 'Romansh AD', de: 'Romanisch AD', fr: 'Romanche AD', it: 'Romansh AD' },
  { code: 'rm_ad', en: 'Romansh AD', de: 'Romanisch AD', fr: 'Romanche AD', it: 'Romansh AD' },
] as const;

type ExtraAudioLanguagesInLocale<T extends keyof typeof EXTRA_AUDIO_LANGUAGES[number]> = {
  [key in typeof EXTRA_AUDIO_LANGUAGES[number]['code']]: typeof EXTRA_AUDIO_LANGUAGES[number][T];
};
export const getExtraAudioLanguagesInLocale = <T extends keyof typeof EXTRA_AUDIO_LANGUAGES[number]>(locale: T) =>
  EXTRA_AUDIO_LANGUAGES.reduce<ExtraAudioLanguagesInLocale<T>>(
    (arr, l) => ({
      ...arr,
      [l.code]: l[locale] || l.de,
    }),
    {} as ExtraAudioLanguagesInLocale<T>
  );
